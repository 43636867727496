import React from "react"
import { Link } from "gatsby"
import Icons from "../components/Icons"

function Card({ image, icon, excerpt, heading, date, to, ...rest }) {
  return (
    <Link
      to={to}
      className="group transform rounded-lg hover:-translate-y-2 duration-200 bg-gray-100 dark:bg-gray-600 p-6 flex flex-col sm:flex-row md:flex-col"
      {...rest}
    >
      <div className="mx-auto sm:mr-8 md:mr-auto">
        <div className="rounded-lg overflow-hidden fill-neutral-200 dark:fill-neutral-300 p-8 w-48 lg:w-64 bg-primary-700">
          {Icons[icon]}
        </div>
      </div>
      <div>
        <div className="mt-4 flex items-baseline gap-x-2 justify-between">
          <span className="uppercase text-primary-700 dark:text-gray-200 font-bold text-xs tracking-wide">
          </span>
          <span className="text-sm text-gray-600 dark:text-gray-300">{date}</span>
        </div>
        <h3 className="mt-1 font-bold text-lg dark:text-gray-200 group-hover:underline">
          {heading}
        </h3>
        <div className="prose dark:prose-invert">
          {excerpt}
        </div>
      </div>
    </Link>
  )
}

export default Card

import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export function NoContact() {
  return (
    <svg
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
      className="bg-gray-200"
    >
      <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
      <circle cx="12" cy="7" r="4"></circle>
    </svg>
  )
}

function Image({ image, alt, ...rest }) {
  if (image && image.url) {
    // this is an image coming from Netlify CMS
    return <img src={image.url} {...rest} alt={alt} />
  } else if (image && image.childImageSharp) {
    // this should be an image processed by gatsby-plugin-image
    const imageRef = getImage(image)
    return <GatsbyImage image={imageRef} alt={alt} {...rest} />
  } else {
    return rest.placeholder ? (
      <div className={rest.className ? rest.className : ""}>
        {rest.placeholder}
      </div>
    ) : (
      <div className="bg-gray-100 p-4 text-gray-600">Image not defined.</div>
    )
  }
}

export default Image

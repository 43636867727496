import React from "react"
import Card from "./Card"
import Image from "./Image"

/**
 * Basic container to add max-w-7xl, some p-x and p-y
 */
export const Container = ({ className, children }) => {
  return (
    <div
      className={`max-w-7xl mx-auto px-6 my-10 mx-auto lg:px-8 lg:my-16 ${
        className ? className : ""
      }`}
    >
      {children}
    </div>
  )
}

/**
 * Basic style for page section heading
 */
export const SectionHeading = ({ children }) => {
  return (
    <h2 className="text-2xl font-medium text-gray-800 dark:text-gray-200 sm:text-3xl lg:text-4xl">
      {children}
    </h2>
  )
}

/**
 * Basic style for page section heading
 */
export const SectionSubHeading = ({ children }) => {
  return (
    <h3 className="text-xl font-medium text-gray-800 dark:text-gray-200 sm:text-2xl lg:text-3xl">
      {children}
    </h3>
  )
}

/**
 * Text image split section, with image on the right by default
 */
export const TextImageSplit = ({ image, children, imageFirst = false, hideSmall = false, split = "md" }) => {
  return (
    <Container>
      <div className={`flex flex-col ${split}:flex-row gap-6 lg:gap-8`}>
        <div
          className={`w-full ${ image ? 'lg:w-1/2' : '' } ${
            imageFirst ? `order-last` : ""
          }`}
        >
          <div className="prose dark:prose-invert max-w-none mx-auto">
            {children}
          </div>
        </div>

        { image ?
            <div className={`flex items-center justify-center w-full lg:w-1/2 ${hideSmall ? 'hidden md:block' : ''}`}>
              <Image
                className={`rounded-md h-full w-full object-cover max-${split}:max-h-96`}
                image={image.image || image}
                alt={image.alt || ""}
              />
            </div>
            : ''
        }
      </div>
    </Container>
  )
}

export function Hero ({image}) {
  return (
    <Container className="-mt-20 sm:-mt-24 lg:-mt-28 pt-16">
      <Image className="rounded-md max-h-96 object-cover" image={image?.image || image} alt={image?.alt || ""} />
    </Container>
  )
}

/**
 * Cards for info previews, laid out in a grid
 * columns: 1 (xs), 2 (sm) and 3 (lg)
 */
export const CardSet = ({ className, infos }) => {
  return (
    <div
      className={`grid grid-cols-1 mt-6 gap-x-6 gap-y-8 md:grid-cols-3 lg:gap-y-12 xl:gap-x-8 ${className}`}
    >
      {infos.map(({ node: info }, idx) => {
        const fm = info.frontmatter
        return (
          <Card
            image={fm.featuredimage}
            icon={fm.icon}
            heading={fm.title}
            date={fm.date}
            to={info.fields.slug}
            excerpt={info.excerpt}
            key={idx}
          />
        )
      })}
    </div>
  )
}

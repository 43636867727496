import React from "react"

// A simple header without an image
export function Header({ heading, subheading }) {
  return (
    <header className="bg-primary-700 pt-24 pb-12 sm:pt-28 sm:pb-16 md:pt-32 md:pb-20 lg:pt-36 lg:pb-24">
      <div className="mx-auto max-w-4xl text-center px-6 lg:px-8">
        <h1 className="text-4xl font-bold text-white sm:text-5xl lg:text-6xl">
          {heading}
        </h1>
        {subheading ? (
          <p className="mt-3 text-primary-100 sm:text-lg lg:text-xl lg:mt-6">
            {subheading}
          </p>
        ) : null}
      </div>
    </header>
  )
}

// A different header for the project page
export function InfoHeader({ heading, subheading }) {
  return (
    <header className="bg-primary-700 pt-24 pb-12 sm:pt-28 sm:pb-16 md:pt-32 md:pb-20 lg:pt-36 lg:pb-24">
      <div className="mx-auto max-w-4xl text-center px-6 lg:px-8">
        <h1 className="mt-2 text-2xl font-bold text-white sm:text-3xl lg:text-4xl">
          {heading}
        </h1>
        {subheading ? (
          <p className="uppercase mt-3 text-primary-100 sm:text-lg lg:text-xl lg:mt-6">
              <span className="uppercase text-primary-700 dark:text-gray-200 font-bold text-xs tracking-wide">
                {subheading}
              </span>
          </p>
        ) : null}
      </div>
    </header>
  )
}

export default Header

import React from "react"
import { Helmet } from "react-helmet"

function MyHelmet({ title, description, canonical }) {
  return (
    <Helmet>
      {title ? <title>{title} | TC Freinsheim</title> : null}
      {title ?  <meta property="og:title" content={title} /> : null}
      {description ? <meta name="description" content={description} /> : null}
      {description ?  <meta property="og:description" content={description} /> : null}
      {canonical ? <link rel="canonical" href={canonical} /> : null}
    </Helmet>
  )
}

export default MyHelmet

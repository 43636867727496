import React from "react"

/**
 * Section for markdown content (prose)
 * applies max width and padding
 */
export function Content({ className, html }) {
  return (
      <div
        className={`prose prose-primary dark:prose-invert max-w-none sm:prose-lg lg:prose-xl text-lg leading-relaxed mx-auto ${className ? className : ''}`}
        dangerouslySetInnerHTML={{ __html: html }}
      />
  )
}

export default Content
